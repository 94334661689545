import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AWPTextTrackKind, PlaybackState } from "@tv4/avod-web-player-common";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { DebugOverlay } from "./components/DebugOverlay/DebugOverlay";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";
import { PlaybackButton } from "./components/PlaybackButton";
import Poster from "./components/Poster";
import { SkinPreviewControls } from "./components/SkinPreviewControls/SkinPreviewControls";
import { Spinner } from "./components/spinner/Spinner";
import useAutoHideUI from "./hooks/useAutoHideUI";
import useCore from "./hooks/useCore";
import useSkinVisibilityChangeEvent from "./hooks/useSkinVisibilityChangeEvent";
import { CoreProvider } from "./providers/CoreProvider";
import { useLayoutSize, useUIVisibility } from "./providers/LayoutProvider";
import { useMetadata } from "./providers/MetadataProvider";
import { GradientMode, SkinContainer, SkinWrapper } from "./styles";
const SkinPreview = forwardRef(({ core, forceShowSkin, onExitClick, onVideoClick }, ref) => {
    const { poster } = useMetadata();
    const { playerState, controls, adMarkers, currentAdBreak, currentAd, chromeCastManager, options, error, } = useCore(core);
    const { uiVisible } = useUIVisibility();
    const [buttonsEnabled, setButtonsEnabled] = useState(false);
    const layoutSize = useLayoutSize();
    const [started, setStarted] = useState(false);
    const { playbackState, isCasting, autoplayBlocked, debugOverlay } = playerState;
    const idle = playbackState === PlaybackState.IDLE;
    const loading = playbackState === PlaybackState.LOADING;
    const seeking = playbackState === PlaybackState.SEEKING;
    const buffering = playbackState === PlaybackState.BUFFERING;
    const ended = playbackState === PlaybackState.ENDED;
    useEffect(() => {
        controls?.setTextTrack?.({
            id: "-1",
            kind: AWPTextTrackKind.NOTHING,
            label: "",
            language: "",
        });
    }, [controls]);
    const { handleInteraction } = useAutoHideUI();
    useEffect(() => {
        setTimeout(() => {
            setButtonsEnabled(uiVisible);
        }, uiVisible ? 500 : 0);
    }, [uiVisible]);
    useEffect(() => {
        if (playbackState === PlaybackState.IDLE ||
            playbackState === PlaybackState.ENDED) {
            setStarted(false);
        }
        else if (playbackState === PlaybackState.PLAYING) {
            setStarted(true);
        }
    }, [playbackState]);
    const displayPosterContent = () => {
        if (isCasting || (started && !autoplayBlocked))
            return null;
        const hasError = error && Boolean(error);
        const isBlurred = Boolean(error);
        const isLoading = idle || loading;
        const canPlayback = !started || autoplayBlocked;
        return (_jsxs(_Fragment, { children: [_jsx(Poster, { poster: poster, blurred: isBlurred }), hasError && _jsx(ErrorMessage, { error: error, onExitClick: onExitClick }), !hasError && !isLoading && canPlayback && _jsx(PlaybackButton, {})] }));
    };
    const shouldShowSkin = useMemo(() => {
        if (forceShowSkin && playbackState === PlaybackState.PLAYING)
            return true;
        if (isCasting)
            return true;
        return started && !idle && !loading && uiVisible;
    }, [
        isCasting,
        forceShowSkin,
        started,
        idle,
        loading,
        uiVisible,
        playbackState,
    ]);
    useSkinVisibilityChangeEvent(core, shouldShowSkin);
    const getCurrentCoreState = () => {
        return core.getState();
    };
    return (_jsxs(CoreProvider, { playerState: playerState, controls: controls, adMarkers: adMarkers, currentAdBreak: currentAdBreak, currentAd: currentAd, options: options, chromeCastManager: chromeCastManager, getCurrentState: getCurrentCoreState, children: [_jsxs(SkinWrapper, { ref: ref, onPointerMove: handleInteraction, onTouchStart: handleInteraction, "$usePauseAds": false, onPointerUp: !error ? onVideoClick : undefined, "$isActive": uiVisible, children: [!ended && (_jsxs(SkinContainer, { id: "skin-container", visible: shouldShowSkin, size: layoutSize, gradientMode: GradientMode.BOTTOM, buttonsEnabled: buttonsEnabled, children: [_jsx("div", {}), _jsx(SkinPreviewControls, {})] })), displayPosterContent(), (loading || buffering || seeking) && _jsx(Spinner, {})] }), debugOverlay && _jsx(DebugOverlay, {})] }));
});
export default SkinPreview;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CoreEvents, PlaybackState, } from "@tv4/avod-web-player-common";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useChromecastManager, usePlayerState, } from "../providers/CoreProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import ChromeCastButton from "./ChromeCastButton";
const Background = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${({ $isError }) => ($isError ? "1.5rem" : "2em")};
`;
const CastController = styled.div `
  position: absolute;
  bottom: 1em;
`;
const useOverlayText = (playerState, error) => {
    const translations = useTranslations();
    if (error) {
        return translations("chromecast__error", { error: error.message });
    }
    const { playbackState, isAd } = playerState;
    if (isAd)
        return translations("chromecast__commercial");
    switch (playbackState) {
        case PlaybackState.LOADING:
            return translations("chromecast__loading");
        default:
            return translations("chromecast__playing");
    }
};
export const ChromeCastOverlay = () => {
    const playerState = usePlayerState();
    const [error, setError] = useState();
    const manager = useChromecastManager();
    useEffect(() => {
        const errorHandler = (payload) => {
            setError(payload.error);
        };
        const resetError = () => {
            setError(undefined);
        };
        manager?.on(CoreEvents.CHROMECAST_ERROR, errorHandler);
        manager?.on(CoreEvents.CHROMECAST_CONNECTED, resetError);
        return () => {
            manager?.off(CoreEvents.CHROMECAST_ERROR, errorHandler);
            manager?.off(CoreEvents.CHROMECAST_CONNECTED, resetError);
        };
    }, [manager]);
    const overlayText = useOverlayText(playerState, error);
    return (_jsxs(Background, { "$isError": error != undefined, children: [overlayText, (error || playerState.playbackState === PlaybackState.ENDED) && (_jsx(CastController, { children: _jsx(ChromeCastButton, {}) }))] }));
};
export default ChromeCastOverlay;

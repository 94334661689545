import "highlight.js/scss/nord.scss";
import "@tv4/avod-web-player/dist/avod-web-player.all.css";

import { WebPlayer } from "@tv4/avod-web-player/dist/avod-web-player.all.mjs";
import {
  CoreEvents,
  getPersistentID,
  PlayerMode,
  Service,
  StateChangedPayload,
  storage,
  // VisualElement,
} from "@tv4/avod-web-player-common";
import hljs from "highlight.js";

import { getAccessToken, getCredentialTokens } from "./credentials";
import {
  getAssetId,
  getAssetsBasedOnService,
  getCastApplicationId,
  getPlayerSettings,
  getServiceFromURL,
  loadAssetsIntoSelect,
  setCurrentlyActiveAsset,
  toggleQueryParameter,
  updateQueryParameter,
} from "./utils";

const locationUrl = new URL(window.location.href);

const selectAssetElement = document.querySelector<HTMLSelectElement>(
  "#select-asset"
) as HTMLSelectElement;
const loadButton = document.querySelector<HTMLButtonElement>(
  "#load-button"
) as HTMLButtonElement;
const playersContainer =
  document.querySelector<HTMLElement>("#players-container");

const useDevPAPI = document.querySelector<HTMLInputElement>(
  "#enable-dev-papi"
) as HTMLInputElement;
useDevPAPI.checked =
  !!storage.getSessionData("enableDevPapi") ||
  new URL(window.location.href).searchParams.has("devpapi");
useDevPAPI.addEventListener("change", (e) => {
  storage.setSessionData(
    "enableDevPapi",
    (e.target as HTMLInputElement).checked
  );

  toggleQueryParameter("devpapi", 1);

  location.reload();
});

const state = document.querySelector<HTMLPreElement>("#state");

selectAssetElement.onchange = () => {
  updateQueryParameter("assetId", selectAssetElement.value);
};

const startTime = locationUrl.searchParams.has("t")
  ? Number(locationUrl.searchParams.get("t"))
  : undefined;
const iosNativeFullscreen = locationUrl.searchParams.has("iosNativeFullscreen");
const hasPoster = locationUrl.searchParams.has("poster");
const assetId =
  locationUrl.searchParams.get("assetId") || selectAssetElement.value;

const {
  autoplay,
  hideMetadataOutsideFullscreen,
  showMobileMuteButton,
  startMuted,
  usePreviewMode,
  useStartOver,
  useDevCC,
} = getPlayerSettings();

// cleanup any existing instance
// @ts-expect-error it's just how it is
// eslint-disable-next-line no-underscore-dangle -- global
window.__player?.destroy();
(playersContainer as HTMLElement).innerHTML = "";

const service: Service = getServiceFromURL();
let tokenRefresher: number | undefined;

const getTokenRefreshInterval = () => {
  clearInterval(tokenRefresher);
  return window.setInterval(() => {
    getCredentialTokens().then((tokens) => {
      console.debug("Refreshing! New accessToken: ", tokens?.accessToken);
      player.setOptions({
        refreshToken: tokens?.refreshToken,
      });
    });
  }, 60 * 1000);
};

const loadSettings = () => {
  const autoplayEnabled = storage.getSessionData("autoLoadAsset") === true;
  if (autoplayEnabled) updateQueryParameter("autoplay", 1);

  const skipPreroll = storage.getSessionData("skipPreroll") === true;
  if (skipPreroll) updateQueryParameter("t", 20);

  const isPreviewMode = storage.getSessionData("usePreviewMode") === true;
  if (isPreviewMode) updateQueryParameter("preview", 1);

  const hideMetadataOutsideFullscreenValue =
    storage.getSessionData("hideMetadataOutsideFullscreen") === true;
  if (hideMetadataOutsideFullscreenValue)
    updateQueryParameter("hideMetadataOutsideFullscreen", 1);

  const mutedPlayback = storage.getSessionData("mutedPlayback") === true;
  if (mutedPlayback) updateQueryParameter("muted", 1);

  const showMobileMuteButtonValue =
    storage.getSessionData("showMobileMuteButton") === true;
  if (showMobileMuteButtonValue)
    updateQueryParameter("showMobileMuteButton", 1);

  const preferStartover = storage.getSessionData("useStartOver") === true;
  if (preferStartover) updateQueryParameter("useStartOver", 1);

  if (storage.getSessionData("enableDevPAPI")) {
    updateQueryParameter("devpapi", 1);
  }
};

const initialAssetId = getAssetId(assetId, service);

document.addEventListener("DOMContentLoaded", async () => {
  selectAssetElement.options.remove(0); // remove playwright placeholder for tests
  loadAssetsIntoSelect(selectAssetElement, service);
  setCurrentlyActiveAsset(
    selectAssetElement,
    initialAssetId,
    getAssetsBasedOnService(service)
  );
  loadSettings();

  if (new URL(window.location.href).searchParams.has("autoplay")) {
    load(initialAssetId, false);
  }
});

const destroyPlayer = () => {
  if (player) player.destroy();
};

const player: WebPlayer = new WebPlayer(playersContainer as HTMLElement, {
  fullscreenElementId: "fullscreen-element",
  autoplay,
  iosNativeFullscreen,
  poster: hasPoster ? locationUrl.searchParams.get("poster")?.toString() : "",
  castId: getCastApplicationId(service, useDevCC),
  getAccessToken,
  gdprConsent: "1",
  service,
  appName: "Demo HTML5",
  appVersion: "1.33.7",
  playerMode: usePreviewMode ? PlayerMode.PREVIEW : PlayerMode.DEFAULT,
  onBackClick: () => console.log("onBackClick"),
  onCloseClick: () => destroyPlayer(),
  onExitClick: () => console.log("onExitClick"),
  onVideoClick: () => console.log("onVideoPointerUp"),
  muted: startMuted,
  hideMetadataOutsideFullscreen,
  showMobileMuteButton,
  playbackApiURL: useDevPAPI.checked
    ? "https://playback2.a2d-dev.tv"
    : "https://playback2.a2d.tv",
  enableCast: true,
  enableAirplay: true,
  enableFullscreen: true,
  environment: "development",
});

function logEvent(eventType, data): void {
  console[eventType.toLowerCase().includes("error") ? "error" : "debug"](
    `[avod-web-player:demo][${eventType}]`,
    data
  );
}
player.onAll((eventType, data) => {
  switch (eventType) {
    case CoreEvents.TIME_UPDATED:
    case CoreEvents.AD_TIME_UPDATED:
    case CoreEvents.STREAM_INFO_UPDATED:
    case CoreEvents.SUBTITLE_TEXT_CHANGED:
      break;
    case CoreEvents.STATE_CHANGED:
      {
        const statePayload = data as StateChangedPayload;
        if (state) {
          state.innerHTML = hljs.highlight(
            JSON.stringify(statePayload.state, null, 2),
            { language: "JSON" }
          ).value;
        }
      }
      break;
    case CoreEvents.LOADED_PLAYBACK:
      {
        logEvent(eventType, data);
        tokenRefresher = getTokenRefreshInterval();
      }
      break;
    default:
      logEvent(eventType, data);
      break;
    case CoreEvents.ENDED:
      clearInterval(tokenRefresher);
      break;
  }
});

function load(asset: string, userInitiatedPlayback: boolean) {
  if (asset.includes("http")) {
    player.load({ src: asset, startTime, userInitiatedPlayback });
  } else {
    getCredentialTokens().then((tokens) => {
      console.debug(
        "Initial refresh of accessToken on load: ",
        tokens?.accessToken
      );
      player.setOptions({
        refreshToken: tokens?.refreshToken,
      });
      player.load({
        assetId: asset,
        startTime,
        useStartOver,
        enableVisuals: [
          // VisualElement.VOTING_BUTTON,
          // VisualElement.CHANNELS_BUTTON,
        ],
        userInitiatedPlayback,
      });
    });
  }
}

loadButton.onclick = () => {
  load(selectAssetElement?.value, true);
};

// Playwright handlers, need to be exposed on window for tests
Object.assign(window, {
  __player: player,
  __load: () => player.load({ assetId, userInitiatedPlayback: false }),
  __getPersistentID: () => getPersistentID(),
});

import { StreamInfoPayload } from ".";
import {
  AWPAudioTrack,
  AWPTextTrack,
  AWPTextTrackSettings,
} from "./models/track-models";
import { PlayerMode } from "./models/visuals";

export enum PlaybackState {
  IDLE = "idle",
  LOADING = "loading",
  PLAYING = "playing",
  PAUSED = "paused",
  SEEKING = "seeking",
  BUFFERING = "buffering",
  ENDED = "ended",
}

export enum PlaybackMode {
  DEFAULT = "default",
  LIVE_DAI = "liveDai",
  ORIGIN = "origin",
  ORIGIN_FALLBACK = "origin_fallback",
  START_OVER = "startOver",
}

export interface PlayerState {
  playerMode?: PlayerMode;
  playbackState: PlaybackState;
  canSeek: boolean;
  canPause: boolean;
  isAd: boolean;
  isPauseAd: boolean;
  isLive: boolean;
  playbackMode: PlaybackMode;
  isFullscreen: boolean;
  isCasting: boolean;
  autoplayBlocked: boolean;
  muted: boolean;
  volume: number;
  preSeeking: boolean;
  currentTime: number;
  utcCurrentTimeMs?: number;
  duration: number;
  streamInfo?: StreamInfoPayload;
  textTracks: AWPTextTrack[];
  activeTextTrack?: AWPTextTrack | null;
  textTrackSettings: AWPTextTrackSettings;
  audioTracks: AWPAudioTrack[];
  activeAudioTrack?: AWPAudioTrack | null;
  currentBitrate: number;
  debugOverlay: boolean;
  playbackRate: number;
}

export const DEFAULT_PLAYER_STATE: PlayerState = {
  playbackState: PlaybackState.IDLE,
  canSeek: false,
  canPause: false,
  isAd: false,
  isPauseAd: false,
  isLive: false,
  playbackMode: PlaybackMode.DEFAULT,
  isFullscreen: false,
  isCasting: false,
  autoplayBlocked: false,
  muted: false,
  volume: 1,
  preSeeking: false,
  currentTime: 0,
  duration: 0,
  textTracks: [],
  activeTextTrack: null,
  textTrackSettings: {},
  audioTracks: [],
  activeAudioTrack: null,
  streamInfo: undefined,
  currentBitrate: 0,
  debugOverlay: false,
  playbackRate: 1,
};

export type TControls = {
  play?: () => void;
  pause?: () => void;
  mute?: () => void;
  unmute?: () => void;
  toggleMute?: () => void;
  setVolume?: (volume: number) => void;
  seekForward?: (amount: number) => void;
  seekBackward?: (amount: number) => void;
  seekTo?: (position: number, debounce?: boolean) => void;
  seekToLive?: () => void;
  seekToStartOver?: () => void;
  setAudioTrack?: (track: AWPAudioTrack) => void;
  setTextTrack?: (track: AWPTextTrack) => void;
  setSubtitleText?: (text: string) => void;
  setSubtitleTextSize?: (size: number) => void;
  toggleFullscreen?: () => void;
  enterFullscreen?: () => void;
  exitFullscreen?: () => void;
  toggleAirplay?: () => void;
  setPlaybackRate: (num: number) => void;
} | null;

import { AWPTextTrackKind, CoreEvents, defaultTextSizes, EventEmitter, loadScript, localPreferences, NO_TEXT_TRACK, PlaybackMode, } from "@tv4/avod-web-player-common";
let sdkLoadPromise = undefined;
async function loadSDK(receiverAppId) {
    sdkLoadPromise ??= new Promise((resolve) => {
        if (!receiverAppId || !("chrome" in window)) {
            resolve(false);
        }
        else {
            window["__onGCastApiAvailable"] = (isAvailable) => {
                if (!isAvailable) {
                    resolve(false);
                }
                else if (!("cast" in window)) {
                    console.warn("Google Cast Sender SDK not loaded, although load callback was called with isAvailable: true");
                    resolve(false);
                }
                else {
                    cast.framework.CastContext.getInstance().setOptions({
                        receiverApplicationId: receiverAppId,
                        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                    });
                    resolve(true);
                }
            };
            loadScript("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1").catch((error) => {
                console.error("Failed to load Google Cast Sender SDK", error);
                resolve(false);
            });
        }
    });
    return sdkLoadPromise;
}
async function loadWebSender(receiverAppId, gdprConsentString) {
    const sdkLoaded = await loadSDK(receiverAppId);
    if (sdkLoaded) {
        return new WebSender(gdprConsentString);
    }
    return undefined;
}
// TODO: Rename class and package name to CastSender. It doesn't send web. It's not spiderman
class WebSender extends EventEmitter {
    gdprConsentString;
    castContext;
    castSession;
    castMedia;
    timePayload;
    loadingInProgress = false;
    castPlaybackMode = PlaybackMode.DEFAULT;
    constructor(gdprConsentString) {
        if (!window.cast) {
            throw new Error("Cast sender initialized before Google Cast Sender SDK was loaded");
        }
        super();
        this.gdprConsentString = gdprConsentString;
        this.gdprConsentString = gdprConsentString;
        this.timePayload = {
            currentTime: 0,
            duration: 0,
            isInAdBreak: false,
        };
        this.castContext = cast.framework.CastContext.getInstance();
        this.castStateChanged = this.castStateChanged.bind(this);
        this.sessionStateChanged = this.sessionStateChanged.bind(this);
        this.setupCastInstanceListeners();
    }
    castStateChanged(state) {
        console.debug(`CAST_STATE_CHANGED: ${state.castState}`);
        if (state.castState === "NOT_CONNECTED") {
            console.info("Chromecast devices available");
        }
        else if (state.castState === "NO_DEVICES_AVAILABLE") {
            console.info("No Chromecast devices available");
        }
        else if (state.castState === "CONNECTED") {
            console.info("Chromecast device connected");
            this.emit(CoreEvents.CHROMECAST_CONNECTED, undefined);
        }
    }
    sessionStateChanged(event) {
        console.debug(`SESSION_STATE_CHANGED: ${event.sessionState}`, event);
        if (event.sessionState === "SESSION_STARTED") {
            console.info("Chromecast session established", event.sessionState);
            this.emit(CoreEvents.CHROMECAST_SESSION_STARTED, undefined);
        }
        if (event.sessionState === "SESSION_RESUMED") {
            this.emit(CoreEvents.CHROMECAST_SESSION_STARTED, undefined);
            setTimeout(() => {
                // for now, give useCore a chance to set chromeCastManager
                this.emit(CoreEvents.CHROMECAST_CONNECTED, undefined);
            }, 1000);
            this.castSession = event.session;
            this.setupCastSessionListeners(event.session);
            this.sendMessage({ type: "getTextTracks" });
            this.sendMessage({ type: "getAudioTracks" });
            const isLive = this.isLive();
            if (isLive)
                this.emit(CoreEvents.CHROMECAST_LIVE_STATE_CHANGED, { isLive });
        }
        if (event.sessionState === "SESSION_ENDED") {
            const sessionData = event.session.getMediaSession();
            if (sessionData?.media?.contentId) {
                this.emit(CoreEvents.CHROMECAST_SESSION_ENDED, {
                    contentId: sessionData.media.contentId,
                    currentTime: this.timePayload.currentTime,
                });
            }
            this.emit(CoreEvents.CHROMECAST_DISCONNECTED, undefined);
        }
        if (event.sessionState === "SESSION_START_FAILED") {
            console.error(`Cast session failed to start: ${event?.errorCode}`, event);
        }
    }
    setupCastInstanceListeners() {
        if (this.castContext.getCastState() === "CONNECTED") {
            setTimeout(() => {
                // for now, give useCore a chance to set chromeCastManager
                this.emit(CoreEvents.CHROMECAST_CONNECTED, undefined);
            }, 1000);
        }
        this.castContext.addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.castStateChanged);
        this.castContext.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, this.sessionStateChanged);
        this.emit(CoreEvents.CHROMECAST_INITIALIZED, undefined);
    }
    async cast({ playbackMode, ...loadOptions }) {
        this.loadingInProgress = true;
        if (this.castContext.getCastState() === "CONNECTED") {
            this.emit(CoreEvents.CHROMECAST_CONNECTED, undefined);
            this.emit(CoreEvents.LOADING_PLAYBACK, undefined);
            this.emit(CoreEvents.CHROMECAST_LIVE_STATE_CHANGED, { isLive: false });
        }
        if (!this.castContext.getCurrentSession()) {
            try {
                this.castSession = await this.requestSession();
            }
            catch (err) {
                console.error("Failed to connect to Chromecast", err);
                throw err;
            }
        }
        const session = this.castContext.getCurrentSession();
        if (session) {
            this.castSession = session;
            this.setupCastSessionListeners(session);
            this.sendMessage({ type: "assetId", value: loadOptions.assetId });
            if (loadOptions.accessToken) {
                this.sendMessage({
                    type: "authorizationToken",
                    value: loadOptions.accessToken,
                });
            }
            if (this.gdprConsentString) {
                this.sendMessage({
                    type: "gdprConsentString",
                    value: this.gdprConsentString,
                });
            }
            // TODO: Wouldn't it be better to send playbackMode directly instead
            const isStartOver = playbackMode === PlaybackMode.START_OVER;
            const isOrigin = playbackMode === PlaybackMode.ORIGIN ||
                playbackMode === PlaybackMode.ORIGIN_FALLBACK;
            if (!loadOptions.preferredTextTrackLanguage) {
                const preferredTextTrackLanguage = localPreferences.getPreferredText(this.isLive());
                // If the user choose to disable subs, getPreferredText will return null.
                // For the cast receiver this is instead a separate param
                Object.assign(loadOptions, preferredTextTrackLanguage
                    ? { preferredTextTrackLanguage }
                    : { enableTextTrack: false });
            }
            if (!loadOptions.preferredAudioLanguage) {
                loadOptions.preferredAudioLanguage =
                    localPreferences.getPreferredAudio();
            }
            // Receiver gets the manifest from API, but chromecast needs just some random manifest url to trigger
            // the loadMedia process in the receiver.
            const mediaInfo = new chrome.cast.media.MediaInfo(loadOptions.assetId, "string");
            const metadata = new chrome.cast.media.GenericMediaMetadata();
            metadata.title = loadOptions.title;
            mediaInfo.metadata = metadata;
            mediaInfo.customData = { ...loadOptions, isOrigin, isStartOver };
            const loadRequest = new chrome.cast.media.LoadRequest(mediaInfo);
            return this.castContext
                ?.getCurrentSession()
                ?.loadMedia(loadRequest)
                .then(() => {
                console.info("chromecast: loadMedia success", loadRequest);
                this.emit(CoreEvents.RESUME, this.timePayload);
                this.sendMessage({ type: "getTextTracks" });
                this.sendMessage({ type: "getAudioTracks" });
                const isLive = this.isLive();
                if (isLive)
                    this.emit(CoreEvents.CHROMECAST_LIVE_STATE_CHANGED, { isLive });
                this.loadingInProgress = false;
            }, (errorCode) => {
                console.error("chromecast: loadMedia failed", errorCode);
                this.loadingInProgress = false;
            });
        }
    }
    stop() {
        const session = this.castContext.getCurrentSession();
        if (!session)
            return;
        // TODO: do we need error handling here?
        session.endSession(true);
        this.emit(CoreEvents.CHROMECAST_DISCONNECTED, undefined);
    }
    getControls() {
        return {
            play: () => this.handlePlay(),
            pause: () => this.handlePause(),
            seekTo: (position) => {
                this.handleSeek(position);
            },
            seekToLive: () => {
                // using 48h here as that's the maximum DVR window we have atm
                this.handleSeek(48 * 3600);
            },
            seekToStartOver: () => {
                this.handleSeek(0);
            },
            seekForward: (seekAmount) => {
                this.handleSeek(this.timePayload.currentTime + seekAmount);
            },
            seekBackward: (seekAmount) => {
                this.handleSeek(this.timePayload.currentTime - seekAmount);
            },
            mute: () => {
                this.changeVolume(0);
            },
            unmute: () => {
                // no-op
            },
            toggleMute: () => {
                if (!this.castSession) {
                    console.warn("Tried to toggle mute without an active castSession");
                    return;
                }
                const muted = !this.castSession.isMute();
                const volume = this.castSession.getVolume();
                this.castSession.setMute(muted);
                this.emit(CoreEvents.VOLUME_CHANGED, { muted, volume });
            },
            setVolume: (volume) => {
                this.emit(CoreEvents.VOLUME_CHANGED, {
                    volume,
                    muted: volume === 0,
                });
                this.changeVolume(volume);
            },
            setTextTrack: (track) => {
                if (this.castSession) {
                    this.sendMessage({
                        type: "changeTextTrack",
                        value: track.id !== "-1" ? track.id : undefined,
                    });
                }
            },
            setSubtitleTextSize: (size) => {
                const type = "setSubtitlesSize";
                switch (size) {
                    case defaultTextSizes.SMALL.size:
                        this.sendMessage({ type, value: "small" });
                        break;
                    case defaultTextSizes.MEDIUM.size:
                        this.sendMessage({ type, value: "medium" });
                        break;
                    case defaultTextSizes.LARGE.size:
                        this.sendMessage({ type, value: "large" });
                        break;
                    case defaultTextSizes.XLARGE.size:
                        this.sendMessage({ type, value: "xlarge" });
                }
                this.emit(CoreEvents.SUBTITLE_TEXT_SIZE_CHANGED, { size });
            },
            setAudioTrack: (track) => {
                if (!this.castSession)
                    return;
                this.sendMessage({ type: "changeAudioTrack", value: track.id });
            },
            setPlaybackRate: () => {
                // noop until CC supports this feature
            },
        };
    }
    isIdleAndHasErrors() {
        return false;
        /*
        return (
          this.sender.session?.playbackState === PlaybackStates.Stopped &&
          this.isInErrorState
        );
        */
    }
    async isCasting() {
        const MAX_WAIT_TIME_MS = 1300;
        const POLLING_INTERVAL_MS = 10;
        const castContext = cast.framework.CastContext.getInstance();
        let castSession;
        return new Promise((resolve) => {
            const pollingTimer = window.setInterval(() => {
                castSession = castContext.getCurrentSession() ?? undefined;
                if (castSession?.getMediaSession()) {
                    clearInterval(pollingTimer);
                    clearTimeout(maxWaitTimer);
                    resolve(true);
                }
            }, POLLING_INTERVAL_MS);
            const maxWaitTimer = window.setTimeout(() => {
                clearInterval(pollingTimer);
                resolve(!!castSession);
            }, MAX_WAIT_TIME_MS);
        });
    }
    getCurrentChromecastSessionState() {
        if (this.loadingInProgress) {
            // fix for fast back-to-back load clicks preventing starting new load request before previous has finished
            return undefined;
        }
        const currentSession = cast.framework.CastContext.getInstance()?.getCurrentSession();
        const mediaSession = currentSession?.getMediaSession();
        return {
            content: {
                contentId: mediaSession?.media?.contentId,
            },
            session: currentSession,
        };
    }
    async requestSession() {
        console.debug("Chromecast: Session requested...", this.castContext);
        // requestSession doesn't return the session. It returns a "nullable" error code (string enum)
        // https://developers.google.com/cast/docs/reference/web_sender/cast.framework.CastContext#requestSession
        const errorCode = await this.castContext.requestSession();
        const currentSession = this.castContext.getCurrentSession();
        if (!currentSession) {
            throw new Error(`Requesting chromecast session failed with code: ${errorCode}`);
        }
        console.info("Chromecast: Session request successful");
        return currentSession;
    }
    setupCastSessionListeners(castSession) {
        castSession.addEventListener(cast.framework.SessionEventType.MEDIA_SESSION, (mediaEvent) => {
            console.info("Chromecast: MEDIA_SESSION ", mediaEvent);
            this.castMedia = mediaEvent.mediaSession;
        });
        castSession.addMessageListener("urn:x-cast:avod.chromecast", this.messageListener.bind(this));
    }
    isLive() {
        // update media session (needed for instance when moving from one content into another)
        this.castSession = this.castContext.getCurrentSession() ?? undefined;
        this.castMedia = this.castSession?.getMediaSession() ?? undefined;
        return this.castMedia?.media?.streamType === "LIVE";
    }
    sendMessage = (message) => {
        if (this.castSession) {
            this.castSession.sendMessage("urn:x-cast:avod.chromecast", message);
        }
        else {
            console.error(`Chromecast: Can't send message. No session`, message);
        }
    };
    async messageListener(namespace, message) {
        try {
            const parsedMessage = JSON.parse(message);
            console.log("message: ", parsedMessage);
            if (namespace !== "urn:x-cast:avod.chromecast") {
                return;
            }
            switch (parsedMessage.type) {
                case "receiverVersion":
                    console.info("Chromecast: Receiver version", parsedMessage.value);
                    break;
                case "assetId":
                    this.emit(CoreEvents.CHROMECAST_CONTENT_UPDATED, {
                        contentId: parsedMessage.value,
                    });
                    this.updateCastMedia();
                    break;
                case "mediaFinished":
                    this.emit(CoreEvents.ENDED, this.timePayload);
                    break;
                case "playbackCapabilities": {
                    this.emit(CoreEvents.PLAYBACK_RESTRICTIONS, {
                        canSeek: parsedMessage.value.seek,
                        canPause: parsedMessage.value.pause,
                    });
                    break;
                }
                case "progressData":
                    this.timePayload = {
                        currentTime: parsedMessage.position,
                        duration: parsedMessage.duration,
                        isInAdBreak: parsedMessage.isInAdBreak ?? false,
                    };
                    this.emit(CoreEvents.TIME_UPDATED, this.timePayload);
                    break;
                case "liveProgressData":
                    if (parsedMessage) {
                        // TODO: Also ensure not paused?
                        const { start, end } = parsedMessage.liveSeekableRange;
                        const currentTime = parsedMessage.currentTime;
                        const duration = end - start;
                        const utcCurrentTimeMs = parsedMessage.utcCurrentTimeMs;
                        this.timePayload = {
                            currentTime,
                            duration,
                            isInAdBreak: parsedMessage.isInAdBreak ?? false,
                            utcCurrentTimeMs,
                        };
                        this.emit(CoreEvents.TIME_UPDATED, this.timePayload);
                    }
                    break;
                case "nextEpisodeAssetId":
                    if (parsedMessage.value) {
                        // fetchNextAsset(parsedMessage.value);
                    }
                    break;
                case "streamType":
                    // appStore.dispatch(setChromecastStreamType(parsedMessage.value));
                    break;
                case "adBreakStarted":
                    this.emit(CoreEvents.CHROMECAST_BREAK_START, {
                        adBreak: parsedMessage.data,
                    });
                    console.log("ad break started: ", parsedMessage.data);
                    break;
                case "adBreakEnded":
                    this.emit(CoreEvents.CHROMECAST_BREAK_END, undefined);
                    break;
                case "adStarted":
                    this.emit(CoreEvents.CHROMECAST_AD_START, { ad: parsedMessage.data });
                    console.log("ad started: ", parsedMessage.data);
                    break;
                case "adEnded":
                    this.emit(CoreEvents.CHROMECAST_AD_END, undefined);
                    break;
                case "receiverError":
                    this.emit(CoreEvents.CHROMECAST_ERROR, {
                        error: {
                            code: parsedMessage.error.code,
                            message: parsedMessage.error.message,
                        },
                    });
                    this.loadingInProgress = false;
                    break;
                case "textTracks":
                    this.synchronizeTextTracks(parsedMessage);
                    break;
                case "audioTracks":
                    this.synchronizeAudioTracks(parsedMessage);
                    break;
                case "playbackModeChanged":
                    this.handlePlaybackModeChange(parsedMessage.data);
                    break;
                case "adMarkers": {
                    this.emit(CoreEvents.AD_MARKERS_UPDATED, {
                        adMarkers: parsedMessage.value,
                    });
                    break;
                }
                default:
            }
        }
        catch (_e) {
            // ignore, invalid message
        }
    }
    updateCastMedia() {
        if (!this.castContext)
            return;
        this.castMedia =
            this.castContext.getCurrentSession()?.getMediaSession() ?? undefined;
    }
    handlePlaybackModeChange(data) {
        const playbackMode = {
            LIVEDAI: PlaybackMode.LIVE_DAI,
            STARTOVER: PlaybackMode.START_OVER,
            ORIGIN: PlaybackMode.ORIGIN,
        }[String(data.playbackMode)] || PlaybackMode.DEFAULT;
        if (playbackMode !== this.castPlaybackMode) {
            let isLive = true;
            if (data.playbackMode === "DEFAULT" && !this.isLive()) {
                isLive = false;
            }
            this.emit(CoreEvents.CHROMECAST_LIVE_STATE_CHANGED, { isLive });
            this.castPlaybackMode = playbackMode;
            this.emit(CoreEvents.PLAYBACK_MODE_SET, {
                playbackMode,
                originStartTime: data.currentTime,
                autoplay: true,
            });
        }
    }
    handleSeek(position) {
        const seekRequest = new chrome.cast.media.SeekRequest();
        seekRequest.currentTime = position;
        this.castMedia?.seek(seekRequest, () => {
            console.debug("Chromecast: Seek success");
        }, () => {
            console.error("Chromecast: Seek failed");
        });
    }
    handlePlay() {
        // The SDK wants some useless params
        // https://developers.google.com/cast/docs/reference/web_sender/chrome.cast.media.Media#play
        this.castMedia?.play(new chrome.cast.media.PlayRequest(), () => undefined, () => console.warn("Failed to play"));
        this.emit(CoreEvents.RESUME, this.timePayload);
        this.sendMessage({ type: "getTextTracks" });
        this.sendMessage({ type: "getAudioTracks" });
        const isLive = this.isLive();
        if (isLive)
            this.emit(CoreEvents.CHROMECAST_LIVE_STATE_CHANGED, { isLive });
    }
    handlePause() {
        // The SDK wants some useless params
        // https://developers.google.com/cast/docs/reference/web_sender/chrome.cast.media.Media#pause
        this.castMedia?.pause(new chrome.cast.media.PauseRequest(), () => undefined, () => console.warn("Failed to pause"));
        this.emit(CoreEvents.PAUSED, this.timePayload);
    }
    changeVolume(volume) {
        const isMuted = volume === 0;
        const castVolume = new chrome.cast.Volume(volume, isMuted);
        const volumeRequest = new chrome.cast.media.VolumeRequest(castVolume);
        this.castMedia?.setVolume(volumeRequest, () => {
            console.debug("Chromecast: Volume set to:", volume);
        }, () => {
            console.error("Chromecast: Failed to set volume");
        });
    }
    synchronizeTextTracks(payload) {
        if (!payload.textTracks?.availableTextTracks?.length) {
            this.emit(CoreEvents.TEXT_TRACK_CHANGED, {
                activeTextTrack: undefined,
                textTracks: [],
            });
            return;
        }
        const castTextTracks = payload.textTracks.availableTextTracks.map((track) => this.convertSessionTextTrackToTextTrack(track));
        castTextTracks.push(NO_TEXT_TRACK);
        const castActiveTracks = payload.textTracks.activeTextTracks;
        const activeTextTrack = castActiveTracks.length > 0
            ? this.convertSessionTextTrackToTextTrack(castActiveTracks[0])
            : castTextTracks.find((track) => track.language === "");
        this.emit(CoreEvents.TEXT_TRACK_CHANGED, {
            activeTextTrack,
            textTracks: castTextTracks,
        });
    }
    synchronizeAudioTracks(payload) {
        if ((payload.audioTracks?.availableAudioTracks?.length || 0) < 2) {
            this.emit(CoreEvents.AUDIO_TRACK_CHANGED, {
                activeAudioTrack: undefined,
                audioTracks: [],
            });
            return;
        }
        const castAudioTracks = payload.audioTracks.availableAudioTracks.map((track) => this.convertSessionAudioTrackToAudioTrack(track));
        const castActiveTracks = payload.audioTracks.activeAudioTracks;
        const activeAudioTrack = this.convertSessionAudioTrackToAudioTrack(castActiveTracks[0]);
        this.emit(CoreEvents.AUDIO_TRACK_CHANGED, {
            activeAudioTrack,
            audioTracks: castAudioTracks,
        });
    }
    convertSessionTextTrackToTextTrack(track) {
        return {
            id: track.id,
            language: track.language,
            label: track.name.toLowerCase() === "" ? "Av" : track.name,
            kind: AWPTextTrackKind.SUBTITLES,
        };
    }
    convertSessionAudioTrackToAudioTrack(track) {
        return {
            id: track.id,
            language: track.language,
            label: track.name,
        };
    }
    destroy() {
        this.castContext.removeEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.castStateChanged);
        this.castContext.removeEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, this.sessionStateChanged);
        super.destroy();
    }
}
export { loadWebSender };

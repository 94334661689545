import { jsx as _jsx } from "react/jsx-runtime";
import { createPendingPromise, PlayerMode, Service, } from "@tv4/avod-web-player-common";
import { createRef } from "react";
import { createRoot } from "react-dom/client";
import { KeyboardShortcuts } from "./KeyboardShortcuts";
import { AccessTokenContext } from "./providers/AccessTokenContext";
import { ClientCallbackContext } from "./providers/ClientCallbackContext";
import { CustomButtonContext } from "./providers/CustomButtonContext";
import { LayoutProvider } from "./providers/LayoutProvider";
import { MetadataProvider } from "./providers/MetadataProvider";
import { TranslationsProvider } from "./providers/TranslationsProvider";
import Skin from "./Skin";
import SkinPreview from "./SkinPreview";
export { CustomButton } from "./components/CustomButton";
export class SkinController {
    core;
    keyboardShortcuts;
    enabledVisuals = [];
    onPlayWhenEnded;
    elementRoot;
    reactRoot;
    pendingRender;
    skinComponentRef;
    rafId = 0;
    props = {};
    constructor({ enableShortcuts, core, root, onPlayWhenEnded, }) {
        this.core = core;
        const elementRoot = document.createElement("div");
        elementRoot.classList.add("avod-web-player-skin");
        root.appendChild(elementRoot);
        this.elementRoot = elementRoot;
        this.reactRoot = createRoot(elementRoot);
        this.onPlayWhenEnded = onPlayWhenEnded;
        if (enableShortcuts) {
            this.keyboardShortcuts = new KeyboardShortcuts({
                playerCore: this.core,
                onPlayWhenEnded,
                asset: this.props.asset,
            });
        }
        this.pendingRender = createPendingPromise();
        this.skinComponentRef = createRef();
        this.update();
    }
    getEnabledVisuals() {
        return this.enabledVisuals;
    }
    async awaitRender() {
        return this.pendingRender.promise;
    }
    // createRoot().render is asynchronous and seems to have no way to report when render has complete. monitor is used to detect when dom has rendered.
    monitorRender() {
        if (this.rafId !== 0) {
            window.cancelAnimationFrame(this.rafId);
        }
        if (!this.pendingRender.completed) {
            // render should be complete when ref has a value
            if (this.skinComponentRef.current) {
                this.pendingRender.resolve();
            }
            // keep monitoring
            else {
                this.rafId = window.requestAnimationFrame(() => this.monitorRender());
            }
        }
    }
    update(updateProps = {}) {
        this.props = {
            ...this.props,
            ...updateProps,
        };
        this.keyboardShortcuts?.updateKeyboardShortcutsOptions({
            asset: this.props.asset,
        });
        const { forceShowSkin, showInactivePrompt, onBackClick, onCloseClick, onExitClick, onVideoClick, enableVisuals, onShareClick, service = Service.DEFAULT, playerMode, hideMetadataOutsideFullscreen, hideSubtitlesMenu, showMobileMuteButton, playerProgressTintColor, thumbnails, getAccessToken, enableFullscreen, onUserSelectedPlaybackRate, getContentMetadata, customButton, noInitialLoadSpinner, } = this.props;
        this.enabledVisuals = enableVisuals;
        const isPreviewMode = playerMode === PlayerMode.PREVIEW;
        if (isPreviewMode)
            this.keyboardShortcuts?.destroy();
        const SkinComponent = isPreviewMode ? SkinPreview : Skin;
        this.reactRoot.render(_jsx(TranslationsProvider, { service: service, children: _jsx(ClientCallbackContext.Provider, { value: {
                    onUserSelectedPlaybackRate,
                    getContentMetadata,
                }, children: _jsx(CustomButtonContext.Provider, { value: { customButton }, children: _jsx(AccessTokenContext.Provider, { value: getAccessToken || (() => undefined), children: _jsx(MetadataProvider, { asset: this.props.asset, poster: this.props.poster, children: _jsx(LayoutProvider, { root: this.elementRoot, enableVisuals: enableVisuals, playerMode: playerMode, onShareClick: onShareClick, hideMetadataOutsideFullscreen: hideMetadataOutsideFullscreen, hideSubtitlesMenu: hideSubtitlesMenu, showMobileMuteButton: showMobileMuteButton, playerProgressTintColor: playerProgressTintColor, enableFullscreen: enableFullscreen, noInitialLoadSpinner: noInitialLoadSpinner, children: _jsx(SkinComponent, { ref: this.skinComponentRef, core: this.core, thumbnails: thumbnails, forceShowSkin: isPreviewMode ? true : forceShowSkin, showInactivePrompt: showInactivePrompt, onBackClick: onBackClick, onCloseClick: onCloseClick, onExitClick: onExitClick, onVideoClick: onVideoClick, onPlayWhenEnded: this.onPlayWhenEnded }) }) }) }) }) }) }));
        this.monitorRender();
    }
    destroy() {
        this.keyboardShortcuts?.destroy();
        this.reactRoot.unmount();
    }
}

import "@tv4/avod-web-player-core/dist/index.css";
import "./player.scss";
import { AWPError, CoreEvents, ensureAWPError, ERROR_CATEGORY, ErrorLevel, EventEmitter, getImageProxyUrl, getRemoteConfigValue, getSelectedDrm, getUserId, initRemoteConfig, isMtvOrUutisetService, PlaybackMode, PlaybackState, PLAYER_ERROR, PlayerMode, resolveMediaType, setRemoteConfigValue, storage, uuid, } from "@tv4/avod-web-player-common";
import { Core, getRemainingAdImmunity, } from "@tv4/avod-web-player-core";
import { canPlayVideo } from "@tv4/avod-web-player-device-capabilities";
import { PlaybackAPI, streamInfoService } from "@tv4/avod-web-player-http";
import { CustomButton, SkinController, } from "@tv4/avod-web-player-skin";
import { NpawTracker, } from "@tv4/avod-web-player-tracking";
import { TrackingManagerProxy } from "./TrackingManagerProxy";
import { getAssetMetadata, mapPlayResponse } from "./util/services";
import { createFinnpanelTracking, createGtmCoreTracking, createKilkayaTrackingIfNeeded, createNielsenTracking, createNpawInitOptions, createWireVideoTracking, } from "./util/webPlayerTracking";
const defaultOptions = {
    playerMode: PlayerMode.DEFAULT,
    getAccessToken: () => undefined,
    enableCast: true,
    enableAirplay: true,
    enableFullscreen: true,
    environment: "production",
    enableShortcuts: true,
};
const PLAYER_NAME = "web-player";
const DESTROYED_ERROR = "WebPlayer instance destroyed. Calls to this method after destroying the player indicates bugs in the player integration.";
class WebPlayer extends EventEmitter {
    destroyed = false;
    core;
    skinController;
    playbackApi;
    trackingManagerProxy;
    npawSessionGroupId;
    allowDrmLicenseRefetch = true;
    userId;
    options; // ! assigned in method called from constructor
    media;
    metadata;
    capabilities = {
        seek: true,
        pause: true,
        skip_ads: false,
        stream_switch: false,
    };
    startOverMedia;
    originMedia;
    currentAssetId;
    currentSource;
    adTags;
    noAds;
    userInitiatedPlayback = false;
    service;
    streaminfoUrl;
    container;
    mutableState = {
        cancelled: false,
    };
    constructor(root, opts) {
        super();
        initRemoteConfig(opts.service);
        this.service = opts.service || getRemoteConfigValue("SERVICE");
        // Check localstorage cast receiver id override, for testing purposes
        // ex: localStorage.setItem("player.CCRAID", '"ASDF1234"')
        // Note that it must be set to a JSON.stringified string
        const CAST_ID_KEY = "CCRAID"; // Chromecast Custom Receiver App Id
        const castId = storage.getData(CAST_ID_KEY) || opts?.castId;
        this.userId = getUserId(opts.getAccessToken()) || "";
        this.updateOptions({
            ...defaultOptions,
            ...opts,
            castId,
        });
        this.playbackApi = new PlaybackAPI({
            ...this.options,
            url: this.options.playbackApiURL || getRemoteConfigValue("PLAYBACK_API_URL"),
            includeCredentials: this.options.playbackApiIncludeCredentials,
        });
        this.container = this.createContainerElement({
            aspectRatio: this.options.aspectRatio,
        });
        root.appendChild(this.container);
        if (this.options.environment !== "production") {
            console.log("\n\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            console.log("!!!!!!!!! DEV MODE !!!!!!!!!");
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!\n\n\n");
            const devNote = document.createElement("div");
            devNote.innerText = "DEV MODE";
            devNote.classList.add("dev_mode_notification");
            this.container.appendChild(devNote);
        }
        this.core = new Core(this.container, {
            ...this.options,
            loopVideo: this.options.loopVideo ?? false,
            fullscreenElementId: this.options.fullscreenElementId || this.container.id,
            playerMode: this.options.playerMode || PlayerMode.DEFAULT,
            userId: this.userId,
        });
        this.core.onAll((eventType, data) => {
            this.emit(eventType, data);
            this.trackingManagerProxy?.on(eventType, data);
            this.core.notifyCoreEvent(eventType, data);
            switch (eventType) {
                case CoreEvents.PLAYBACK_MODE_CHANGE:
                    return this.handlePlaybackModeChange(data);
                case CoreEvents.USER_ACTIVE_CONFIRM:
                    this.core.getControls()?.play?.();
                    return this.skinController?.update({ showInactivePrompt: false });
                case CoreEvents.USER_ACTIVE_DECLINE:
                    return this.skinController?.update({ showInactivePrompt: false });
                case CoreEvents.CHROMECAST_SESSION_STARTED:
                    return this.reset();
                case CoreEvents.CHROMECAST_CONTENT_UPDATED:
                    return this.handleChromecastContentUpdate(data.contentId);
                case CoreEvents.CHROMECAST_SESSION_ENDED:
                    return this.resumePlaybackFromChromecast(data);
                case CoreEvents.DRM_LICENSE_ERROR:
                    return this.handleDrmLicenseError(data);
                case CoreEvents.AUTOPLAY_BLOCKED:
                    if (this.options.playerMode !== PlayerMode.PREVIEW)
                        return;
                    this.core.getControls()?.mute?.();
                    requestAnimationFrame(() => {
                        this.core.getControls()?.play?.();
                    });
                    break;
                case CoreEvents.ERROR:
                    if (this.destroyed)
                        return;
                    if (data && "error" in data && data.error?.fatal) {
                        this.reset();
                    }
                    break;
                default:
                    break;
            }
        });
        if (!this.options.noUi) {
            this.skinController = new SkinController({
                core: this.core,
                root: this.container,
                onPlayWhenEnded: () => this.restartAsset(true),
                enableShortcuts: this.options.enableShortcuts ?? true,
            });
            // SkinController will not accept all options in constructor
            this.updateSkinController();
        }
    }
    resumePlaybackFromChromecast(data) {
        const { contentId, currentTime } = data;
        if (!contentId) {
            return;
        }
        this.load({
            assetId: contentId,
            startTime: currentTime,
            userInitiatedPlayback: false,
            useStartOver: this.core.getState().playbackMode === PlaybackMode.START_OVER,
        });
    }
    setupTracking(media, metadata, playbackMode, trackers) {
        if (!media || !metadata) {
            return;
        }
        this.discardTrackingManagerProxy();
        if (metadata.tracking.GA) {
            const gtmCoreTracking = createGtmCoreTracking({
                trackingMetadata: metadata.tracking.GA,
            });
            trackers.push(gtmCoreTracking);
        }
        const kilkayaTracking = createKilkayaTrackingIfNeeded({
            trackingMetadata: metadata.tracking.linkPulse,
        });
        if (kilkayaTracking) {
            trackers.push(kilkayaTracking);
        }
        if (metadata.tracking.nielsen) {
            trackers.push(createNielsenTracking({
                trackingMetadata: metadata.tracking.nielsen,
                userInitiatedPlayback: this.userInitiatedPlayback,
            }));
        }
        if (metadata.tracking.videoTracking) {
            trackers.push(createWireVideoTracking({
                config: metadata.tracking.videoTracking,
                getAccessToken: this.options.getAccessToken,
            }));
        }
        if (isMtvOrUutisetService(this.service)) {
            trackers.push(createFinnpanelTracking(this.service));
        }
        this.trackingManagerProxy = new TrackingManagerProxy([
            // new DebugTracker(),
            ...trackers,
        ], this.getTrackerOptions(media, metadata, playbackMode));
    }
    getTrackerOptions(media, metadata, playbackMode) {
        return {
            sessionId: this.core.sessionId,
            media,
            metadata,
            playbackMode,
            playerMode: this.options.playerMode,
            npawSessionGroupId: this.npawSessionGroupId,
            userId: this.userId,
        };
    }
    // This method is used to report PlaybackApi errors to npaw that occur before the TrackingManagerProxy has been created
    handleStartupMetadataAndMediaError(npawTracker, error) {
        console.log("Failed to get metadata and media", error);
        npawTracker.setup({
            npawSessionGroupId: null, // define here to avoid typescript error, overridden with value from getTrackerOptions.
            ...this.getTrackerOptions(),
            assetId: this.currentAssetId,
            userId: this.userId,
        });
        const errorPayload = {
            currentTime: 0,
            duration: 0,
            error,
            isInAdBreak: false,
        };
        npawTracker.onError(errorPayload);
        this.core.emitCoreEvent(CoreEvents.ERROR, errorPayload);
    }
    updateOptions(options) {
        this.options = {
            ...this.options,
            ...options,
        };
        setRemoteConfigValue("ACCESS_TOKEN", this.options.getAccessToken() || "");
    }
    async setOptions(options) {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.updateOptions(options);
        this.core.setOptions({
            ...this.options,
            userId: this.userId,
            fullscreenElementId: this.options.fullscreenElementId || this.container.id,
        });
        this.updateSkinController();
    }
    updateSkinController() {
        this.skinController?.update({
            ...this.options,
            asset: this.metadata?.asset,
            poster: getImageProxyUrl(this.options.poster || this.metadata?.asset?.image || undefined),
            service: this.service,
            thumbnails: this.media?.thumbnails,
        });
    }
    createContainerElement({ aspectRatio, }) {
        const container = document.createElement("div");
        container.id = "container";
        container.classList.add("avod-web-player-container");
        container.style.setProperty("--aspect-ratio", aspectRatio === "9x16" ? "177.77777778%" : "56.25%");
        return container;
    }
    getChromeCastManager() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getChromeCastManager();
    }
    restartAsset(userInitiatedPlayback) {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        // TODO throwing here does not do much. This should be emitted as
        //  a fatal error.
        if (!this.currentAssetId) {
            if (this.currentSource) {
                return this.loadSrc(this.currentSource);
            }
            throw new AWPError({
                context: "player",
                message: "Attempted restart without asset id.",
                category: ERROR_CATEGORY.DEFAULT,
                code: PLAYER_ERROR.WRONG_INPUT,
                errorLevel: ErrorLevel.CLIENT,
            });
        }
        this.setOptions({ autoplay: true });
        this.load({
            assetId: this.currentAssetId,
            enableVisuals: this.skinController?.getEnabledVisuals(),
            userInitiatedPlayback,
        });
    }
    async getAssetMetadata(assetId) {
        return getAssetMetadata({
            assetId,
            service: this.service,
            playbackApi: this.playbackApi,
            mutableState: this.mutableState,
        });
    }
    async handleChromecastContentUpdate(assetId) {
        const metadata = await this.getAssetMetadata(assetId);
        if (this.destroyed || !metadata)
            return;
        this.currentAssetId = assetId;
        this.metadata = metadata;
        this.updateSkinController();
    }
    async load(options) {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.reset();
        this.adTags = options.adTags;
        this.noAds = options.noAds;
        this.userInitiatedPlayback = options.userInitiatedPlayback;
        // generate a new session id each time load is called, used for the wire tracking session
        this.core.generateTrackingSessionId();
        if ("src" in options) {
            this.currentAssetId = undefined;
            this.currentSource = options.src;
            this.loadSrc(options.src, options.startTime);
        }
        else {
            this.currentSource = undefined;
            this.currentAssetId =
                "assetId" in options ? options.assetId : options.asset.id;
            const npawTracker = new NpawTracker(createNpawInitOptions({
                playerName: PLAYER_NAME,
                userId: this.userId,
                appVersion: this.options.appVersion,
                appName: this.options.appName,
                assetId: this.currentAssetId,
                environment: this.options.environment,
            }));
            try {
                const asset = "asset" in options
                    ? options.asset
                    : await this.playbackApi.playRequest(options.assetId);
                if (this.destroyed)
                    return;
                await this.loadAsset({
                    ...options,
                    asset,
                    npawTracker,
                });
                if (this.destroyed)
                    return;
            }
            catch (e) {
                if (this.destroyed)
                    return;
                // TODO e is not guaranteed to be AWP error
                this.handleStartupMetadataAndMediaError(npawTracker, e);
            }
        }
        if (options.enableVisuals) {
            this.skinController?.update({ enableVisuals: options.enableVisuals });
        }
    }
    async loadAsset(options) {
        this.allowDrmLicenseRefetch = true; // Reset this flag when loading a new asset
        this.npawSessionGroupId = uuid(); // This should only change when consumer loads a new asset
        const { metadata, media, capabilities, originMedia, startOverMedia } = mapPlayResponse({
            playbackApi: this.playbackApi,
            asset: options.asset,
        });
        this.media = media;
        this.metadata = metadata;
        this.capabilities = capabilities;
        this.startOverMedia = startOverMedia;
        this.originMedia = originMedia;
        this.streaminfoUrl = options.asset.playbackItem.streaminfoUrl;
        if (!this.metadata || !metadata)
            return;
        if (this.streaminfoUrl) {
            streamInfoService.init({
                url: this.streaminfoUrl,
                adsRequired: !this.capabilities.skip_ads,
                onUpdate: (streamInfo, adBreakUpdated) => {
                    this.core.emitCoreEvent(CoreEvents.STREAM_INFO_UPDATED, streamInfo);
                    if (adBreakUpdated) {
                        this.core.emitCoreEvent(CoreEvents.AD_MARKERS_UPDATED, {
                            adMarkers: streamInfoService.adMarkers,
                        });
                    }
                },
            });
        }
        this.updateSkinController();
        if (await this.core.isCasting(this.options.playerMode)) {
            if (this.destroyed)
                return;
            this.handleCastSessionPlayback(options.asset.metadata.id, options.startTime);
            return;
        }
        // TODO: Use a setting to achieve "current behavior" (necessary changes/best approach
        // need to be investigated)
        let initialPlaybackMode = PlaybackMode.DEFAULT;
        if (this.streaminfoUrl) {
            if (startOverMedia && options.useStartOver) {
                initialPlaybackMode = PlaybackMode.START_OVER;
            }
            else if (this.core.getState().playbackMode === PlaybackMode.ORIGIN ||
                // fallback to origin if startOverMedia is not available
                options.useStartOver) {
                if (options.useStartOver) {
                    // start from the beginning if startOver was requested
                    options.startTime = 0;
                }
                initialPlaybackMode = PlaybackMode.ORIGIN;
            }
            else if (media?.isLive && media?.isStitched) {
                initialPlaybackMode = PlaybackMode.LIVE_DAI;
            }
        }
        const playbackModePayload = {
            playbackMode: initialPlaybackMode,
            autoplay: this.options.autoplay ?? false,
        };
        if (initialPlaybackMode === PlaybackMode.ORIGIN) {
            playbackModePayload.originStartTime = options.startTime;
        }
        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, playbackModePayload);
        const initialMedia = {
            [PlaybackMode.START_OVER]: startOverMedia,
            [PlaybackMode.ORIGIN]: originMedia,
        }[initialPlaybackMode] || media;
        this.setupTracking(initialMedia, metadata, initialPlaybackMode, [
            options.npawTracker,
        ]);
        await this.loadCore({
            media: initialMedia,
            startTime: options.startTime,
        });
        if (this.options.environment !== "production") {
            const remainingAdImmunity = getRemainingAdImmunity(this.metadata?.asset.id, getRemoteConfigValue("AD_IMMUNITY_DURATION"));
            if (remainingAdImmunity) {
                console.log("[Ad immunity]", new Date().toLocaleTimeString(), `✅ Reloaded Web Player with ${remainingAdImmunity} seconds remaining ad immunity for asset`, this.metadata?.asset.id);
            }
        }
    }
    async handleCastSessionPlayback(assetId, startTime) {
        const castManager = this.core.getChromeCastManager();
        const sessionState = castManager?.getCurrentChromecastSessionState();
        if (!sessionState)
            return;
        const sessionAssetId = sessionState.content?.contentId;
        if (!sessionState.session ||
            (sessionAssetId && sessionAssetId === assetId)) {
            if (!sessionState.session) {
                console.warn("Chromecast is connected, but there is no session, did chromecast crash?", { sessionState });
            }
            return;
        }
        this.currentAssetId = assetId;
        this.changeChromecastProgram(assetId, startTime);
    }
    async loadCore({ media, startTime, autoplay, }) {
        /**
         * core.load will initialize pause ads which relies on skin being rendered,
         * so if core.load is called before skin has rendered, execution will crash.
         * loadAsset will make requests to load content before calling core.load,
         * so skin will have enough time to render.
         * loadSrc will directly call core.load with url before skin has had time to
         * render.
         * ideally core should not assume that skin has rendered arbitrary dom
         * content, but for now always wait for skin to complete render before
         * core.load is called.
         */
        const options = {
            media,
            startTime,
            autoplay,
            availablePlaybackModes: this.getAvailablePlaybackModes(),
            capabilities: this.capabilities,
            metadata: this.metadata,
            adTags: this.adTags,
            noAds: this.noAds,
        };
        try {
            await this.skinController?.awaitRender();
            if (this.destroyed)
                return;
            await this.core.load(options);
        }
        catch (originalError) {
            if (this.destroyed)
                return;
            if (originalError instanceof AWPError &&
                this.originMedia &&
                options.media !== this.originMedia) {
                options.media = this.originMedia;
                // TODO: emit the error as non-fatal here, so that we can see it in npaw,
                // but it must not count for the AFS
                const cachedPlaybackMode = this.core.getState().playbackMode;
                // Reset core before emitting PLAYBACK_MODE_SET, since it would
                // otherwise be reset to default.
                this.core.reset();
                this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
                    playbackMode: PlaybackMode.ORIGIN_FALLBACK,
                    autoplay: options.autoplay ?? false,
                    ...(cachedPlaybackMode === PlaybackMode.START_OVER && {
                        originStartTime: 0,
                    }),
                });
                return this.loadCore(options);
            }
            if (this.destroyed)
                return;
            const error = ensureAWPError(originalError);
            this.emit(CoreEvents.LOAD_PLAYBACK_ERROR, { error });
        }
    }
    loadSrc(src, startTime) {
        this.reset();
        this.loadCore({
            media: {
                manifestUrl: src,
                type: resolveMediaType(src),
                isLive: false,
                isStartOver: false,
                isStitched: false,
                loadPreference: "speed",
            },
            startTime,
        });
    }
    async changeChromecastProgram(assetId, startTime) {
        const metadata = await this.getAssetMetadata(assetId);
        if (this.destroyed || !metadata)
            return;
        const { activeAudioTrack, playbackMode } = this.core.getState();
        const { getAccessToken, refreshToken } = this.options;
        const { title } = metadata.asset;
        const remainingAdImmunity = getRemainingAdImmunity(metadata.asset.id, getRemoteConfigValue("AD_IMMUNITY_DURATION"));
        await this.core.getChromeCastManager()?.cast({
            assetId,
            title,
            currentTime: startTime,
            // TODO Rework, make cast only use refresh token
            accessToken: getAccessToken(),
            refreshToken,
            playbackMode,
            remainingAdImmunity,
            preferredAudioLanguage: activeAudioTrack?.language,
        });
        if (this.destroyed)
            return;
        this.metadata = metadata;
        this.updateSkinController();
        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
            playbackMode: PlaybackMode.DEFAULT,
            autoplay: true,
        });
    }
    play() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.play?.();
    }
    pause() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.pause?.();
    }
    seek(targetInSeconds) {
        this.core.getControls()?.seekTo?.(targetInSeconds, false);
    }
    getPositionInSeconds() {
        return this.core.getState().currentTime;
    }
    mute() {
        this.core?.getControls()?.mute?.();
    }
    unmute() {
        this.core?.getControls()?.unmute?.();
    }
    setVolume(vol) {
        if (vol > 1 || vol < 0 || Number.isNaN(vol)) {
            throw new Error(`Bad number provided to volume controls: ${vol}`);
        }
        this.core?.getControls()?.setVolume?.(vol);
    }
    setPlaybackRate(rate) {
        this.core.getControls()?.setPlaybackRate(rate);
    }
    toggleFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.toggleFullscreen?.();
    }
    enterFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.enterFullscreen?.();
    }
    exitFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.exitFullscreen?.();
    }
    showSkin() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.skinController?.update({
            forceShowSkin: true,
        });
    }
    hideSkin() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.skinController?.update({
            forceShowSkin: false,
        });
    }
    isFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getState().isFullscreen;
    }
    isStartOver() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getState().playbackMode === PlaybackMode.START_OVER;
    }
    isPlaying() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getState().playbackState === PlaybackState.PLAYING;
    }
    static async isBrowserSupported(checkDrmSupport = false) {
        const supported = canPlayVideo();
        if (!supported)
            return false;
        if (checkDrmSupport) {
            return !!(await getSelectedDrm());
        }
        return true;
    }
    inactiveUsageDetected() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.skinController?.update({
            showInactivePrompt: true,
        });
        this.core.getControls()?.pause?.();
    }
    getAvailablePlaybackModes() {
        if (this.streaminfoUrl && this.media && this.originMedia) {
            const playbackModes = [
                PlaybackMode.ORIGIN,
                PlaybackMode.LIVE_DAI,
            ];
            if (this.startOverMedia)
                playbackModes.push(PlaybackMode.START_OVER);
            return playbackModes;
        }
        return [PlaybackMode.DEFAULT];
    }
    handlePlaybackModeChange(playbackModePayload) {
        if (!this.media || !this.metadata || !this.currentAssetId)
            return;
        let { playbackMode, originStartTime } = playbackModePayload;
        let chosenMedia = this.media;
        // If yospace startover can be disabled on the back-end, then try to fallback on origin with startTime 0
        if (playbackMode === PlaybackMode.START_OVER &&
            (!this.startOverMedia || !this.metadata.asset.startOver)) {
            playbackMode = PlaybackMode.ORIGIN;
            originStartTime = 0;
        }
        if (playbackMode === PlaybackMode.START_OVER && this.startOverMedia) {
            chosenMedia = this.startOverMedia;
        }
        else if (playbackMode === PlaybackMode.ORIGIN && this.originMedia) {
            chosenMedia = this.originMedia;
            streamInfoService.update();
        }
        // If startover is requested when there's no startover media and we fallback to live, we change playback mode to reflect this.
        // We might actually want the fallback to be Origin in this case?
        if (playbackMode === PlaybackMode.START_OVER &&
            chosenMedia === this.media) {
            playbackMode = PlaybackMode.LIVE_DAI;
        }
        this.core.setVideoFrameToBackground();
        this.core.reset({ resetBackground: false });
        this.setupTracking(chosenMedia, this.metadata, playbackMode, [
            new NpawTracker(createNpawInitOptions({
                playerName: PLAYER_NAME,
                userId: this.userId,
                appVersion: this.options.appVersion,
                appName: this.options.appName,
                assetId: this.currentAssetId,
                environment: this.options.environment,
            })),
        ]);
        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
            playbackMode,
            autoplay: playbackModePayload.autoplay,
        });
        this.loadCore({
            media: chosenMedia,
            startTime: originStartTime,
            autoplay: playbackModePayload.autoplay,
        });
    }
    async handleDrmLicenseError(drmLicenseError) {
        // This function should only be called when there is a DRM license error for currently loaded media, so if media doesn't contain a license, we should do nothing
        if (!this.currentAssetId || !this.media?.license)
            return;
        if (!this.allowDrmLicenseRefetch)
            return this.core.emitCoreEvent(CoreEvents.ERROR, drmLicenseError);
        // Reset immediately to kill the ongoing session
        this.core.reset();
        this.allowDrmLicenseRefetch = false; // Only allow license refetch once per top-level load or when the license has expired
        // TODO this timeout needs to be saved and cleared on destroy/reset
        window.setTimeout(() => {
            this.allowDrmLicenseRefetch = true;
        }, 10 * 60 * 1000); // Allow license refetch again after 10 minutes, when it's expected that the last license fetched has expired
        let media;
        try {
            const metadataAndMedia = mapPlayResponse({
                playbackApi: this.playbackApi,
                asset: await this.playbackApi.playRequest(this.currentAssetId),
            });
            if (this.destroyed)
                return;
            if (metadataAndMedia) {
                media = metadataAndMedia.media;
            }
        }
        catch (playbackApiError) {
            if (this.destroyed)
                return;
            const errorPayload = {
                ...drmLicenseError,
                error: playbackApiError,
            };
            return this.core.emitCoreEvent(CoreEvents.ERROR, errorPayload);
        }
        if (this.destroyed)
            return;
        // If fetching the license was successful, update the license for all media (same license is shared by all)
        if (media?.license) {
            [this.media, this.originMedia, this.startOverMedia].forEach((existingMedia) => {
                if (existingMedia?.license) {
                    existingMedia.license = media?.license;
                }
            });
        }
        let chosenMedia = this.media;
        const wasPlaying = this.isPlaying();
        const playbackMode = this.core.getState().playbackMode;
        if (playbackMode === PlaybackMode.START_OVER && this.startOverMedia) {
            chosenMedia = this.startOverMedia;
        }
        else if ((playbackMode === PlaybackMode.ORIGIN ||
            playbackMode === PlaybackMode.ORIGIN_FALLBACK) &&
            this.originMedia) {
            chosenMedia = this.originMedia;
        }
        this.loadCore({
            media: chosenMedia,
            startTime: drmLicenseError.currentTime,
            autoplay: wasPlaying,
        });
    }
    discardTrackingManagerProxy() {
        this.trackingManagerProxy?.destroy();
        this.trackingManagerProxy = undefined;
    }
    reset() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        // Cancel ongoing playback api requests since it holds a reference
        // to this mutable object.
        this.mutableState.cancelled = true;
        // create a new mutableState object to reset the state
        // for the next playback session.
        this.mutableState = {
            cancelled: false,
        };
        this.core.reset();
        this.media = undefined;
        this.metadata = undefined;
        this.startOverMedia = undefined;
        this.updateSkinController();
        this.discardTrackingManagerProxy();
        streamInfoService.reset();
    }
    destroy() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.playbackApi.destroy();
        this.destroyed = true;
        this.mutableState.cancelled = true;
        super.destroy();
        this.skinController?.destroy();
        this.discardTrackingManagerProxy();
        this.core.destroy();
        streamInfoService.reset();
        this.container.remove();
    }
}
export { CustomButton, PlaybackAPI, WebPlayer };

const UPDATE_INTERVAL = 5000;
let updateInterval;
let streamInfoUrl;
let adsRequired = true;
let duration;
let startTime;
let ended;
let adMarkersMap = new Map();
// TODO make this instantiable per playback session, otherwise different
//  or simultaneous playback sessions will inherit stream info from the
//  previous/sibling session.
/**
 * streamInfoService singleton
 */
export const streamInfoService = {
    async init(options) {
        streamInfoUrl = options.url;
        adsRequired = options.adsRequired;
        const doUpdate = async () => {
            const prevAdStartTimes = [...adMarkersMap.keys()];
            const payload = await this.update();
            const adBreakUpdated = adMarkersMap.size !== prevAdStartTimes.length ||
                adMarkersMap.keys().next().value !== prevAdStartTimes[0];
            options?.onUpdate?.(payload, adBreakUpdated);
        };
        updateInterval = window.setInterval(doUpdate, UPDATE_INTERVAL);
        await doUpdate();
    },
    async update() {
        if (!streamInfoUrl) {
            throw new Error("streamInfoService must be initiated with streamInfoService.init({ url })");
        }
        const response = await fetch(streamInfoUrl);
        const data = await response.json();
        const streamStart = new Date(data.startTime).getTime();
        startTime = streamStart;
        duration = data.durationMs / 1000;
        ended = !!data.ended;
        // ignoring data.liveEdge, because it is the same as startTime + durationMs
        const newAdMarkersMap = new Map();
        for (const adBreak of data.adBreaks || []) {
            const start = (new Date(adBreak.adStart).getTime() - streamStart) / 1000;
            const end = (new Date(adBreak.adEnd).getTime() - streamStart) / 1000;
            const watched = !!adMarkersMap.get(start)?.watched;
            const required = adsRequired;
            // The first ad break may contain ads before the program start, which needs to be excluded
            // Additionally the test streams have duplicate ad breaks, which need to be excluded
            if (start >= 0 && !newAdMarkersMap.has(start)) {
                newAdMarkersMap.set(start, { start, end, watched, required });
            }
        }
        adMarkersMap = newAdMarkersMap;
        if (ended) {
            clearInterval(updateInterval);
        }
        return { startTime, duration, ended };
    },
    reset() {
        streamInfoUrl = undefined;
        duration = undefined;
        startTime = undefined;
        ended = undefined;
        adMarkersMap.clear();
        clearInterval(updateInterval);
    },
    get duration() {
        return duration;
    },
    get startTime() {
        return startTime;
    },
    get ended() {
        return ended;
    },
    get adMarkers() {
        return [...adMarkersMap.values()];
    },
    /** get ad marker at a given position or undefined if there is none */
    getAdMarkerAt(position) {
        return this.adMarkers.find((marker) => marker.start <= position && position < marker.end);
    },
    /** get all ad markers starting between two positions */
    getAdMarkerStartingBetween(start, end) {
        return this.adMarkers.filter((marker) => start <= marker.start && marker.start < end);
    },
    /** Marks the ad marker closest matching the end time as watched */
    markWatchedAdBreak(endTime) {
        // Note, this matching must not be precice, so we can't reude the getAdMarkerAt logic
        const closestMarker = this.adMarkers.reduce((prev, curr) => {
            return Math.abs(curr.end - endTime) < Math.abs(prev?.end - endTime)
                ? curr
                : prev;
        });
        if (closestMarker) {
            closestMarker.watched = true;
        }
        else {
            console.warn("Could not find any ad marker to mark as watched", startTime);
        }
    },
};

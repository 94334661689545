import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useIsChannel, useIsMtvNews247 } from "../providers/MetadataProvider";
import { LayoutSize } from "../util/device";
import { useTracksMenu } from "./audio-text-tracks/TracksMenu/TracksMenu";
import ChromeCastButton from "./ChromeCastButton";
import CurrentTime from "./CurrentTime";
import { Body, ButtonGroup, ButtonsContainer, ControlsContainer, ProgressContainer, } from "./DefaultControls/DefaultControls.styles";
import FullscreenButton from "./FullscreenButton";
import GoToLiveButton from "./GoToLiveButton";
import GoToStartOverButton from "./GoToStartOverButton";
import LiveStatus from "./LiveStatus";
import PlayPauseButton from "./PlayPauseButton";
import ProgressBar from "./progress-bar/ProgressBar";
import RemainingTime from "./RemainingTime";
import SeekBackwardButton from "./SeekBackwardButton";
import SeekForwardButton from "./SeekForwardButton";
import VolumeController from "./VolumeController/VolumeController";
const ChromecastProgressContainer = styled(ProgressContainer) `
  justify-content: flex-end;
`;
export function ChromeCastControls() {
    const { canSeek, isLive, isAd, isPauseAd } = usePlayerState();
    const size = useLayoutSize();
    const isMtvNews247 = useIsMtvNews247();
    // Uutiset (useIsMtvNews247) is a live channel, but excluded from useIsChannel()
    // But for Chromecast we _currently_ want both to work the same way (no seeking)
    const isChannel = useIsChannel() || isMtvNews247;
    const isAnyAd = isAd || isPauseAd;
    const showTimeline = canSeek && !isAnyAd;
    const showSeekbuttons = canSeek && !isAnyAd;
    const { closeTracksMenu, TracksButton, TracksMenu } = useTracksMenu();
    // when smallest layout we show some buttons in the header instead of here
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    const showRemainingTime = !isLive && !isSmallestLayout;
    const isSeekableLiveStream = isLive && !isPauseAd && !isChannel;
    const showStartOverButton = isSeekableLiveStream && !isSmallestLayout;
    return (_jsxs(ControlsContainer, { "$compact": isSmallestLayout, children: [_jsx(Body, { onClick: closeTracksMenu }), _jsx(ChromecastProgressContainer, { "$compact": isSmallestLayout, children: showTimeline && (_jsxs(_Fragment, { children: [_jsx(CurrentTime, {}), _jsx(ProgressBar, {}), isLive && _jsx(LiveStatus, {}), showRemainingTime && _jsx(RemainingTime, {})] })) }), _jsxs(ButtonsContainer, { children: [_jsxs(ButtonGroup, { "$position": "left", children: [!isChannel && _jsx(PlayPauseButton, {}), showStartOverButton && _jsx(GoToStartOverButton, {}), showSeekbuttons && !isSmallestLayout && (_jsxs(_Fragment, { children: [_jsx(SeekBackwardButton, {}), _jsx(SeekForwardButton, {})] }))] }), _jsx(ButtonGroup, { "$position": "center", children: isSmallestLayout ? (_jsxs(_Fragment, { children: [showSeekbuttons && _jsx(SeekBackwardButton, {}), showSeekbuttons && _jsx(SeekForwardButton, {})] })) : (TracksButton && _jsx(TracksButton, {})) }), _jsxs(ButtonGroup, { "$position": "right", children: [isSeekableLiveStream && _jsx(GoToLiveButton, {}), !isSmallestLayout && _jsx(ChromeCastButton, {}), _jsx(VolumeController, {}), !isSmallestLayout && _jsx(FullscreenButton, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { DEFAULT_PLAYER_STATE, } from "@tv4/avod-web-player-common";
import { createContext, useContext } from "react";
const CoreContext = createContext({
    playerState: DEFAULT_PLAYER_STATE,
    controls: null,
    adMarkers: [],
    currentAdBreak: undefined,
    currentAd: undefined,
    options: undefined,
    chromeCastManager: undefined,
});
export function CoreProvider({ playerState, controls, adMarkers, currentAdBreak, currentAd, options, chromeCastManager, getCurrentState, getDebugInfo, children, }) {
    return (_jsx(CoreContext.Provider, { value: {
            playerState,
            controls,
            adMarkers,
            currentAdBreak,
            currentAd,
            options,
            chromeCastManager,
            getCurrentState,
            getDebugInfo,
        }, children: children }));
}
export const usePlayerState = () => {
    const { playerState } = useContext(CoreContext);
    return playerState;
};
export const useControls = () => {
    const { controls } = useContext(CoreContext);
    return controls;
};
export const useAdMarkers = () => {
    const { adMarkers } = useContext(CoreContext);
    return adMarkers;
};
export const useCurrentAdBreak = () => {
    const { currentAdBreak, currentAd } = useContext(CoreContext);
    return { currentAdBreak, currentAd };
};
export const useChromecastManager = () => {
    const { chromeCastManager } = useContext(CoreContext);
    return chromeCastManager;
};
export const useOptions = () => {
    const { options } = useContext(CoreContext);
    return options;
};
export const useDebugInfo = () => {
    const { getDebugInfo } = useContext(CoreContext);
    return getDebugInfo && getDebugInfo();
};

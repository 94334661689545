import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getRemoteConfigValue } from "@tv4/avod-web-player-common";
import { getRemainingAdImmunity } from "@tv4/avod-web-player-core";
import ChromeCastConnectedIconSvg from "../icons/ChromeCastConnectedIcon.svg";
import ChromeCastDisconnectedIconSvg from "../icons/ChromeCastDisconnectedIcon.svg";
import { useAccessToken } from "../providers/AccessTokenContext";
import { useChromecastManager, useOptions, usePlayerState, } from "../providers/CoreProvider";
import { useMetadata } from "../providers/MetadataProvider";
import { PlayerControlButton as Button } from "../styles";
export const ChromeCastButton = () => {
    const { asset } = useMetadata();
    const playerState = usePlayerState();
    const { activeAudioTrack, playbackMode, currentTime, isCasting } = playerState;
    const { refreshToken } = useOptions() || {};
    const getAccessToken = useAccessToken();
    const manager = useChromecastManager();
    const castReady = !!manager;
    const startCasting = async () => {
        if (!asset) {
            // TODO: handle src
            console.log("temp error for missing assetId, could be src");
            return;
        }
        const { id: assetId, title } = asset;
        const remainingAdImmunity = getRemainingAdImmunity(assetId, getRemoteConfigValue("AD_IMMUNITY_DURATION"));
        await manager?.cast({
            assetId,
            title,
            currentTime,
            accessToken: getAccessToken(),
            refreshToken,
            playbackMode,
            remainingAdImmunity,
            preferredAudioLanguage: activeAudioTrack?.language,
        });
    };
    const stopCasting = () => {
        manager?.stop();
    };
    return (_jsxs(_Fragment, { children: [!isCasting && castReady && (_jsx(Button, { "aria-label": `cast`, onClick: startCasting, children: _jsx(ChromeCastDisconnectedIconSvg, {}) })), isCasting && castReady && (_jsx(Button, { "aria-label": `stop cast`, onClick: stopCasting, children: _jsx(ChromeCastConnectedIconSvg, {}) }))] }));
};
export default ChromeCastButton;

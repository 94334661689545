import { VASTParser } from "@dailymotion/vast-client";
import VMAPParser from "@dailymotion/vmap";
import { AWPError, ERROR_CATEGORY, ErrorLevel, getFreeWheelConfig, getMRMSiteSectionId, isMtvNews247Id, parsePauseAds, PLAYER_ERROR, queryStringFromObject, } from "@tv4/avod-web-player-common";
import { mapAdBreakObject } from "./adBreakMapper";
function randomNumber() {
    return Math.floor(Math.random() * 10000000000).toString();
}
export class FreeWheelAdServer {
    baseUrl;
    pauseAdSlot;
    customParameters;
    service;
    mrmNetworkId;
    profileDevice;
    sectionDevice;
    adServerOptions;
    vastParser;
    pid; // ! because value is set in function called from constructor
    persistentID;
    wireSessionId = "";
    gdprConsent;
    currentContentId;
    isLive = false;
    destroyed = false;
    pauseAds = [];
    pauseAdsLoadPromise;
    fwPVRandom = randomNumber();
    fwVPRandom = randomNumber();
    constructor({ service, userId, profileDevice, sectionDevice, persistentID, gdprConsent, customParameters, }) {
        const config = getFreeWheelConfig(service);
        this.vastParser = new VASTParser();
        this.persistentID = persistentID;
        this.setUserId(userId); // will set initial pid value
        this.gdprConsent = gdprConsent;
        this.service = service;
        this.mrmNetworkId = config.networkId;
        this.profileDevice = profileDevice;
        this.sectionDevice = sectionDevice;
        this.baseUrl = config.baseUrl;
        this.pauseAdSlot = config.pauseAdSlot;
        this.customParameters = customParameters;
    }
    setUserId(userId) {
        this.pid = userId || this.persistentID;
    }
    async load({ adServerOptions, isLive, wireSessionId, }) {
        this.adServerOptions = adServerOptions;
        this.isLive = isLive === true;
        this.wireSessionId = wireSessionId || "";
        // any pending pause ads load may no longer be valid when adServerOptions change
        this.pauseAdsLoadPromise = void 0;
        // reset pause ads
        this.pauseAds = [];
        // reset video player random when content changes
        if (this.currentContentId !== this.adServerOptions?.contentId) {
            this.fwVPRandom = randomNumber();
        }
        this.currentContentId = this.adServerOptions?.contentId;
        // start loading pause ads in the background
        void this.loadPauseAds();
    }
    async getPauseAd() {
        let pauseAd = this.pauseAds.shift();
        // start loading more ads in the background if needed
        void this.loadPauseAds();
        // if a pause ad is not available then wait until loading has completed
        if (!pauseAd) {
            await this.pauseAdsLoadPromise;
            pauseAd = this.pauseAds.shift();
        }
        return pauseAd;
    }
    getMRMSiteSectionTag() {
        const liveOrVod = this.isLive ? "live" : "vod";
        return `ondomain_${getMRMSiteSectionId(this.service)}_${this.sectionDevice}_${liveOrVod}`;
    }
    getGlobalParams(isPauseAd) {
        return {
            prof: `${this.mrmNetworkId}:${this.profileDevice}`,
            nw: this.mrmNetworkId,
            flag: isPauseAd
                ? "+sync+play"
                : "+sltp+amcb+nucr+dtrd+scpv+emcr+play+vicb+slcb",
            resp: isPauseAd ? "vast4" : "vmap1+vast4",
            ...(this.adServerOptions
                ? {
                    caid: this.adServerOptions.contentId,
                    csid: this.getMRMSiteSectionTag(),
                    mode: this.isLive ? "live" : "on-demand",
                }
                : {}),
            vprn: this.fwVPRandom,
            pvrn: this.fwPVRandom,
            metr: "7",
            ...this.customParameters?.globalParameters,
        };
    }
    getKeyValues() {
        const subscriptionTag = this.adServerOptions?.tags
            .split(",")
            .find((value) => /^sub_/.test(value));
        return {
            _fw_vcid2: this.pid,
            wire_session_id: this.wireSessionId,
            _fw_continuous_play: "1",
            _fw_player_width: "1920",
            _fw_player_height: "1080",
            ...(this.gdprConsent
                ? {
                    _fw_gdpr: "1",
                    _fw_gdpr_consent: this.gdprConsent,
                }
                : {}),
            ...(subscriptionTag
                ? {
                    sub: subscriptionTag,
                }
                : {}),
            ...this.customParameters?.keyValues,
        };
    }
    getAdURLExtraParams() {
        const isMtvNews247 = isMtvNews247Id(this.adServerOptions?.contentId);
        return {
            ...(isMtvNews247
                ? {
                    slid: "pre",
                    maxd: "60",
                    ptgt: "a",
                    slau: "MTV FI_Preroll",
                    maxa: "2",
                }
                : {}),
        };
    }
    constructUrl(globalParams, keyValues, parameters) {
        /*
          URL STRUCTURE
          http://[environment].v.fwmrm.net/ad/g/1?[globalParams];[keyValues];[ParamsForSlot1];[ParamsForSlot2];...;[ParamsForSlotN];
        */
        let url = `${this.baseUrl}?${queryStringFromObject(globalParams)};${queryStringFromObject(keyValues)}`;
        if (parameters) {
            url += `;${queryStringFromObject(parameters)}`;
        }
        return url;
    }
    getPauseAdUrl() {
        return this.constructUrl(this.getGlobalParams(true), this.getKeyValues(), {
            ptgt: "a",
            slau: this.pauseAdSlot,
            maxa: "1",
        });
    }
    async loadPauseAds() {
        // only load more pause ads when there are none available and not already loading
        if (this.pauseAds.length === 0 &&
            this.adServerOptions &&
            !this.pauseAdsLoadPromise) {
            const pending = this.getPauseAds()
                .then((pauseAds) => {
                if (this.destroyed)
                    return;
                // if pauseAdsLoadPromise have changed during asynchronous operation then result may not be valid
                if (pending === this.pauseAdsLoadPromise) {
                    this.pauseAds = pauseAds || [];
                }
            })
                .finally(() => {
                if (this.destroyed)
                    return;
                if (pending === this.pauseAdsLoadPromise) {
                    this.pauseAdsLoadPromise = void 0;
                }
            });
            if (this.destroyed)
                return;
            this.pauseAdsLoadPromise = pending;
        }
        if (this.destroyed)
            return;
        await this.pauseAdsLoadPromise;
    }
    async getPauseAds() {
        const url = this.getPauseAdUrl();
        try {
            const vastObject = await this.vastParser.getAndParseVAST(url);
            if (this.destroyed)
                return [];
            const ads = vastObject.ads;
            return parsePauseAds(ads);
        }
        catch (error) {
            if (this.destroyed)
                return [];
            if (this.isVastParserAdBlockError(error)) {
                throw new AWPError({
                    context: "http",
                    message: "AdBlocker detected",
                    category: ERROR_CATEGORY.ADS,
                    code: PLAYER_ERROR.AD_BLOCKER,
                    errorLevel: ErrorLevel.USER,
                    raw: error,
                });
            }
            return [];
        }
    }
    isVastParserAdBlockError(error) {
        return error instanceof Error && error.message === "XHRURLHandler:  (0)";
    }
    async getLinearAds() {
        const url = this.getAdURL();
        try {
            const vmapObject = await this.fetchAdsVMAP(url);
            if (this.destroyed)
                return [[], null];
            const adBreaks = await this.resolveAds(vmapObject);
            if (this.destroyed)
                return [[], null];
            return [adBreaks, null];
        }
        catch (e) {
            if (this.destroyed)
                return [[], null];
            return [
                null,
                new AWPError({
                    context: "http",
                    message: e instanceof Error
                        ? e.message
                        : "Unknown error in freewheel ad server",
                    category: ERROR_CATEGORY.ADS,
                    code: PLAYER_ERROR.AD_BLOCKER,
                    errorLevel: ErrorLevel.USER,
                    raw: e,
                }),
            ];
        }
    }
    getAdURL() {
        return this.constructUrl(this.getGlobalParams(false), this.getKeyValues(), this.getAdURLExtraParams());
    }
    async fetchAdsVMAP(u) {
        const response = await fetch(u, {
            // this is required to support tearsheets, freewheel sets a cookie that when is sent to the
            // vmap endpoint will return only a particular set of ads.
            credentials: "include",
        });
        const xml = await response.text();
        const xmlParser = new DOMParser();
        const xmlDoc = xmlParser.parseFromString(xml, "text/xml");
        const vmapObject = new VMAPParser(xmlDoc);
        return vmapObject;
    }
    async resolveAds(vmapObject) {
        const options = this.getVastParserOptions();
        this.vastParser.initParsingStatus(options);
        const unsortedBreaks = await this.parseAdBreaks(vmapObject.adBreaks, options);
        if (this.destroyed)
            return [];
        return unsortedBreaks.sort((a, b) => a.timeOffset - b.timeOffset);
    }
    async parseAdBreaks(adBreaks, options) {
        const adBreakPromises = adBreaks.map(async (adBreak) => {
            if (!this.hasValidBreaktype(adBreak.breakType))
                return adBreak;
            const adsInBreak = adBreak.adSource?.vastAdData;
            if (!adsInBreak)
                return mapAdBreakObject(adBreak, []);
            const adBreakAds = await this.vastParser.parseVAST({ documentElement: adsInBreak }, options);
            if (this.destroyed)
                return;
            return mapAdBreakObject(adBreak, adBreakAds.ads);
        });
        if (this.destroyed)
            return Promise.resolve([]);
        return Promise.all(adBreakPromises);
    }
    getVastParserOptions() {
        return {
            isRootVAST: true,
            timeout: 10 * 1000,
            withCredentials: true,
            wrapperLimit: 10,
            followAdditionalWrappers: true,
        };
    }
    hasValidBreaktype(adBreakType) {
        const breakTypes = adBreakType.split(",");
        return breakTypes.includes("linear");
    }
    destroy() {
        this.destroyed = true;
    }
}
